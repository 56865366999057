.sider:global(.ant-layout-sider) {
  position: absolute;
  height: 100vh;
  z-index: 10;
}

.sider-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: #002140;
  padding: 1rem;
  color: white;
}

.title:global(.ant-typography) {
  margin-left: 1rem;
  margin-top: 1rem;

  color: white;
}

.content {
  overflow: auto;
  padding: 1.5rem;
}

.wrapper {
  margin-left: 50px;
  width: calc(100% - 50px);
  display: flex;
  flex-direction: column;
}
