.table {
  border-color: #d4d4d4 !important;

  .cellGray {
    background: #ebebeb !important;
  }

  thead {
    th:global(.ant-table-cell) {
      white-space: nowrap;
      text-align: center;
      border-color: #d4d4d4;
      font-size: 18px;
      background-color: #ffffff;
    }

    th:nth-last-child(-n + 2) {
      font-weight: 700;
    }

    th:first-child {
      text-align: left;
    }
  }

  tbody {
    border-color: #d4d4d4 !important;

    td {
      text-align: center;
      white-space: nowrap;
      border-color: #d4d4d4 !important;
    }

    td:nth-last-child(-n + 1) {
      font-weight: 700;
    }

    input {
      text-align: center;
      white-space: nowrap;
      border: 0px;
      background-color: transparent;
    }

    input:global(.ant-input:focus) {
      box-shadow: none;
    }
  }

  tfoot {
    td {
      font-weight: 700;
      text-align: center;
      white-space: nowrap;
      border-color: #d4d4d4 !important;
    }
  }
}

.cellTaskName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
}

.summaryCell {
  border-top: 1px solid #d4d4d4;
}

.summaryCellGray {
  border-top: 1px solid #d4d4d4;
  background: #ebebeb;
}

.summaryCell.summaryTitle {
  text-align: left;
}

.dateHeader {
  display: flex;
  flex-direction: column;
  align-items: center;

  .day {
    color: #a3a3ac;
    font-size: 14px;
    line-height: 23.91px;
  }

  .dayDate {
    font-weight: 500;
    font-size: 24px;
  }

  .mounth {
    color: #b0b0b0d9;
    font-weight: 500;
    font-size: 14px;
  }
}
