.wrapper {
  display: flex;

  .select:global(.ant-select .ant-select-selector) {
    border-radius: 2px 0px 0px 2px;
    max-height: 90px;
    overflow: auto;
  }

  .button {
    border-radius: 0px 2px 2px 0px;
    height: auto;
  }
}
