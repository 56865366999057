.formItem {
  margin-bottom: 0 !important;
}

.rejectAnalysisWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}

.rejectActions {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-end;
  margin-top: auto;
  margin-bottom: 0;
}
