.spin {
  margin: -1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0.5;
}

.absoluteSpin {
  position: absolute;
  background: lightgray;
}
