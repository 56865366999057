body {
  overflow: hidden;
  margin: 0;

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tableWrapper {
  margin-top: 50px;
}

.table-row-light {
  background-color: #ffffff;
}

.table-row-dark {
  background-color: #fbfbfb;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: grey lightgrey;
}

/* Works on Chrome, Edge, and Safari */
// *::-webkit-scrollbar {
//   width: 8px;
//   height: 10px;
// }

*::-webkit-scrollbar-track {
  background: lightgrey;
}

*::-webkit-scrollbar-thumb {
  background-color: grey;
}

.taskTable {
  font-family: "Open Sans", sans-serif;
  .ant-table-row-level-1 {
    background-color: #fcfcfc;
  }

  .ant-table-row-level-1 {
    .ant-radio-wrapper {
      display: none;
    }
  }
}

input.customInput {
  border-radius: unset;
  border-bottom: 1px solid #000;
  padding: 10px 0;
  border-color: #000;

  &:focus,
  &:hover {
    border-bottom: 1px solid #000;
  }
}

/* Убираем стрелочки в input[type="number"] */
input.customInput {
  -moz-appearance: textfield; /* Для Firefox */
}

input.customInput::-webkit-inner-spin-button,
input.customInput::-webkit-outer-spin-button {
  -webkit-appearance: none; /* Для Chrome, Safari и Opera */
  margin: 0; /* Убираем отступы */
}

div.customSelect {
  div.ant-select-selector {
    border-bottom: 1px solid #000 !important;
    align-items: flex-end;
    padding: 0 0 5px 0 !important;
    height: 43px !important;
  }

  span.ant-select-selection-search {
    top: unset !important;
    bottom: 5px !important;
    left: 0 !important;
  }
}

div.customDataPicker {
  border: none;
  padding: 10px 0;
  border-bottom: 1px solid #000;
  border-radius: unset;
  box-shadow: unset !important;
}
