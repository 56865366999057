.header {
  background-color: white !important;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.searchWrapper {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem !important;

  .searchFilter {
    flex: 1;
    max-width: 200px;
  }

  .searchStatusFilter {
    flex: 1;
    min-width: 100px;
  }
}

.analysisBtns {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.date-block {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .date-info {
    display: flex;
    margin: 0 1rem;
    align-items: center;

    :global(.ant-typography) {
      margin-bottom: 0;
    }

    span {
      margin: 0 1rem;
    }
  }
}
