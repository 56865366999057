.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  .pagination {
    margin-top: 1rem !important;
    display: flex;
  }

  .total-info {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 4px 15px;
    border: 1px solid #d9d9d9;
    border-radius: 0.313rem;
    min-width: 150px;

    span {
      margin-right: 0.5rem;
    }

    h5 {
      margin-bottom: 0;
    }
  }
}
