.newAnalysisActions {
  margin-top: auto;
  margin-bottom: 0;
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

.newAnalysisWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
}

.newAnalysisItem {
  width: 100%;
}

.formItem {
  margin-bottom: 0 !important;
}

