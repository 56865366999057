.current {
  &-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 56px;

    .title {
      background: linear-gradient(90.15deg, #000000 0.16%, #1890ff 106.51%),
        #000000;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
      font-weight: 600;
      font-size: 55px;
      line-height: 1;
    }
  }
  &-title-block {
    display: flex;
    align-items: flex-end;
  }

  &-status {
    padding: 2px 12px;
    border-radius: 100px;
    border: 2px solid #1890ff;
    color: #1890ff;
    margin-left: 17px;
    font-size: 16px;
    font-weight: 600;
  }
  &-submitted {
    border-color: #0eac44;
    color: #0eac44;
  }

  &-actions {
    .btn {
      border-radius: 2px;
      margin-left: 18px;
    }
  }
}

.title-block {
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  h3 {
    margin: 0;
  }
}

.tasksMenu > li {
  width: 100%;
}

.tasksMenu .button {
  text-align: left;
}

.wrapper-date-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  .total-info {
    display: flex;
    flex-direction: column;
    align-items: center;

    .total-hours {
      font-size: 24px;
      font-weight: 600;
    }
  }
}

.date-block {
  display: flex;
  align-items: center;

  .add-task-btn {
    font-size: 16px;
    font-weight: 500;
  }

  .date-info {
    display: flex;
    align-items: center;

    :global(.ant-typography) {
      margin-bottom: 0;
    }

    span {
      margin: 0 1rem;
    }
  }
}

.button:global(.ant-btn-link) {
  width: 100%;
  color: black;
}

.taskOverlay :global(.ant-dropdown-menu) {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 350px;
  overflow: auto;
}

// .header {
//   background-color: white !important;
//   box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
// }
