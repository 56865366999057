.taskTableWrapper {
  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.04),
    0px 0.751293px 10.0172px rgba(0, 0, 0, 0.035),
    0px 0.399006px 5.32008px rgba(0, 0, 0, 0.0282725),
    0px 12px 40px rgba(0, 0, 0, 0.04),
    0px 0.751293px 10.0172px rgba(0, 0, 0, 0.035),
    0px 0.399006px 5.32008px rgba(0, 0, 0, 0.0282725);
  border-radius: 6px;
}

.taskTable {
  * {
    font-family: "Open Sans", sans-serif;
  }
  thead * {
    background-color: #fff !important;
  }

  thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(
      .ant-table-row-expand-icon-cell
    ):not([colspan]):before {
    display: none;
  }

  thead > tr > th {
    font-weight: bold;
    border-bottom: 2px solid #eff1f5;
  }

  .ant-table-cell {
    font-family: "OpenSans";
  }
}

.resources {
  display: flex;
  align-items: center;
}

.resourcesBtns {
  margin-left: auto;
  margin-right: 0;
}

.taskTablePagination {
  margin-right: 20px !important;
}
