.title {
  margin-bottom: 1rem !important;
}

.searchPopoverWrapper {
  display: flex;
  align-items: flex-end;
  margin-bottom: 2rem;
}

.checkBox {
  display: flex;
  flex-direction: column;
  margin-right: 2rem;
}

.selectBox {
  margin-right: 2rem;
}

.header {
  background-color: white !important;
  box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
}

.pagination {
  margin-top: 1rem !important;
  display: flex;
  justify-content: flex-end;
}
