.taskCreation {
  background-color: #fafafa;
}

.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 32px 24px 16px;
}

.pageHeader {
  background: #fafafa !important;
  border-bottom: 1px solid #d2d2d2;
}

.taskCreationTop {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 26px;
  border-bottom: 1px solid #d2d2d2;
}

.title {
  background: linear-gradient(90.15deg, #000000 0.16%, #1890ff 106.51%), #000000;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  font-weight: 600;
  font-size: 55px;
}

.actions {
  display: grid;
  column-gap: 14px;
  grid-template-columns: repeat(3, auto);
}
