.header {
  display: flex;
  color: white;
  justify-content: space-between;

  :global(.ant-typography) {
    color: white;
  }

  .user-box {
    display: flex;
    align-items: center;

    :global(.ant-typography) {
      margin-right: 1rem;
    }
  }
}

.userMenu :global(.ant-dropdown-menu-item) {
  text-align: center;
}


.titleContainer {
  display: flex;
  align-items: flex-end;
  padding-bottom: 1em;
  gap: 1em;
}

.title {
  margin: 0 !important;
}